import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    form_visibility: false,
    teachers: [],
    selected_teacher: null,
  },
  getters: {
    form_visibility: state => state.form_visibility,
    teachers: state => state.teachers,
    selected_teacher: state => state.selected_teacher
  },
  mutations: {
    INDEX_TEACHER(state, items) {
      state.teachers = items
    },
    STORE_TEACHER(state, item) {
      state.teachers.unshift(item)
    },
    UPDATE_TEACHER(state, item) {
      let itemIndex = _.findIndex(state.teachers, { id: item.id })
      Vue.set(state.teachers, itemIndex, item)
    },
    UPDATE_TEACHER_IMAGE(state, { item, image_url }) {
      let itemIndex = _.findIndex(state.teachers, { id: item.id })
      item.image_url = image_url
      Vue.set(state.teachers, itemIndex, item)
    },
    DELETE_TEACHER(state, item) {
      let itemIndex = _.findIndex(state.teachers, { id: item.id })
      Vue.delete(state.teachers, itemIndex)
    },
    SHOW_FORM(state) {
      state.form_visibility = true
    },
    HIDE_FORM(state) {
      state.form_visibility = false
    },
    SET_SELECTED_TEACHER(state, item) {
      state.selected_teacher = item
    },
    UNSET_SELECTED_TEACHER(state) {
      state.selected_teacher = null
    }

  },
  actions: {
    async fetchTeacher({ commit }) {
      let response = await axios.get('api/teacher')
      commit('INDEX_TEACHER', response.data.teacher)
    },

    async storeTeacher({ commit }, item) {
      try {
        let response = await axios.post('api/teacher', item);
        commit('STORE_TEACHER', response.data.teacher)
        Vue.toasted.success('Penyimpanan informasi pengajar berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penyimpanan informasi pengajar gagal';
        Vue.toasted.error(message)
      }
    },

    async updateTeacher({ commit }, item) {
      try {
        let response = await axios.put(`api/teacher/${item.id}`, item);
        commit('UPDATE_TEACHER', response.data.teacher)
        Vue.toasted.success('Pembaruan informasi pengajar berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pembaruan informasi pengajar gagal';
        Vue.toasted.error(message)
      }
    },

    async updateTeacherPhoto({ commit }, { item, data }) {
      try {
        let response = await axios.post(`api/teacher/${item.id}/profile-picture`, data);
        commit('UPDATE_TEACHER_IMAGE', { item, image_url: response.data.file_url })
        Vue.toasted.success('Pembaruan foto pengajar berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pembaruan foto pengajar gagal';
        Vue.toasted.error(message)
      }
    },

    async deleteTeacher({ commit }, item) {
      try {
        await axios.delete(`api/teacher/${item.id}`);
        commit('DELETE_TEACHER', item)
        Vue.toasted.success('Penghapusan informasi pengajar berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penghapusan informasi pengajar gagal';
        Vue.toasted.error(message)
      }
    },


  }
}