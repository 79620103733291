<template>
  <div id="app">
    <main-menu></main-menu>
    <router-view style="margin-top: 64.5px" />
  </div>
</template>


<script>
import MainMenu from "@/components/MainMenu.vue";
export default {
  components: { MainMenu },
};
</script>
