import axios from 'axios'
import Vue from "vue";
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    form_visibility: false,
    form_data: null,
    list_visibility: false,
    selected_schedule: null,
  },
  getters: {
    schedules(state, getters, rootState, rootGetters) {
      let selectedPeriod = rootGetters['period/selected_period']

      return selectedPeriod !== null
        ? selectedPeriod.schedules
        : null;
    },
    selected_schedule: state => state.selected_schedule,
    form_visibility: state => state.form_visibility,
    list_visibility: state => state.list_visibility,
    form_data: state => state.form_data
  },
  mutations: {
    RESET(state) {
      state.form_visibility = false
      state.list_visibility = false
      state.selected_schedule = null
      state.form_data = null
    },
    SET_SELECTED_SCHEDULE(state, item) {
      state.selected_schedule = item
    },
    UNSET_SELECTED_SCHEDULE(state) {
      state.selected_schedule = null
    },
    SET_FORM_DATA(state, item) {
      state.form_data = item
    },
    UNSET_FORM_DATA(state) {
      state.form_data = null
    },
    SHOW_LIST(state) {
      state.list_visibility = true
    },
    HIDE_LIST(state) {
      state.list_visibility = false
    },
    SHOW_FORM(state) {
      state.form_visibility = true
    },
    HIDE_FORM(state) {
      state.form_visibility = false
    }
  },
  actions: {
    /**
     * Store single period
     * @param {Object} context
     * @param {Object} item
     */
    async storeSchedule({ rootGetters }, item) {
      try {
        // init period id to item
        item.period_id = rootGetters['period/selected_period']['id']
        let response = await axios.post('api/registration/schedule', item);
        // find the selected period object
        let targetScheduleList = rootGetters['period/selected_period']['schedules']

        // add newly created schedule to the top of the list
        let registeredSchedule = response.data.period_schedule
        // preparing for members relationship
        registeredSchedule.members = []
        targetScheduleList.unshift(registeredSchedule);

        Vue.toasted.success('Penyimpanan jadwal berhasil')
      } catch (error) {
        Vue.toasted.error(error.response.data.message)
      }
    },

    /**
     * Update single period
     * @param {Object} context
     * @param {Object} item
     */
    async updateSchedule({ rootGetters }, item) {
      try {
        let response = await axios.put(`api/registration/schedule/${item.id}`, item);

        // find the selected period object
        let targetScheduleList = rootGetters['period/selected_period']['schedules']
        let targetIndex = _.findIndex(targetScheduleList, { id: item.id });

        // clone the members relationship
        let memberTempList = _.cloneDeep(targetScheduleList[targetIndex]['members'])
        let updatedSchedule = response.data.period_schedule

        // attach the cloned members to the updated schedule
        updatedSchedule.members = memberTempList

        Vue.set(targetScheduleList, targetIndex, updatedSchedule)

        Vue.toasted.success('Pengeditan jadwal berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pengeditan jadwal gagal';
        Vue.toasted.error(message)
      }
    },

    /**
     * Delete single period
     * @param {Object} context
     * @param {Object} item
     */
    async deleteSchedule({ rootGetters }, item) {
      try {
        await axios.delete(`api/registration/schedule/${item.id}`);
        // find the selected period object
        let targetScheduleList = rootGetters['period/selected_period']['schedules']
        let targetIndex = _.findIndex(targetScheduleList, { id: item.id });

        Vue.delete(targetScheduleList, targetIndex)
        Vue.toasted.success('Penghapusan jadwal berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penghapusan jadwal gagal';
        Vue.toasted.error(message)
      }
    },
  }
}