import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    form_visibility: false,
    news: [],
    selected_news: null,
  },
  getters: {
    form_visibility: state => state.form_visibility,
    news: state => state.news,
    selected_news: state => state.selected_news
  },
  mutations: {
    INDEX_NEWS(state, items) {
      state.news = items
    },
    STORE_NEWS(state, item) {
      state.news.unshift(item)
    },
    UPDATE_NEWS(state, item) {
      let itemIndex = _.findIndex(state.news, { id: item.id })
      Vue.set(state.news, itemIndex, item)
    },
    UPDATE_NEWS_IMAGE(state, { item, image_url }) {
      let itemIndex = _.findIndex(state.news, { id: item.id })
      item.image_url_full = image_url
      Vue.set(state.news, itemIndex, item)
    },
    DELETE_NEWS(state, item) {
      let itemIndex = _.findIndex(state.news, { id: item.id })
      Vue.delete(state.news, itemIndex)
    },
    SHOW_FORM(state) {
      state.form_visibility = true
    },
    HIDE_FORM(state) {
      state.form_visibility = false
    },
    SET_SELECTED_NEWS(state, item) {
      state.selected_news = item
    },
    UNSET_SELECTED_NEWS(state) {
      state.selected_news = null
    }

  },
  actions: {
    async fetchNews({ commit }) {
      let response = await axios.get('api/news')
      commit('INDEX_NEWS', response.data.news)
    },

    async storeNews({ commit }, item) {
      try {
        let response = await axios.post('api/news', item);
        commit('STORE_NEWS', response.data.news)
        Vue.toasted.success('Penyimpanan informasi berita berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penyimpanan informasi berita gagal';
        Vue.toasted.error(message)
      }
    },

    async updateNews({ commit }, item) {
      try {
        let response = await axios.put(`api/news/${item.id}`, item);
        commit('UPDATE_NEWS', response.data.news)
        Vue.toasted.success('Pembaruan informasi berita berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pembaruan informasi berita gagal';
        Vue.toasted.error(message)
      }
    },

    async updateNewsPhoto({ commit }, { item, data }) {
      try {
        let response = await axios.post(`api/news/${item.id}/banner-picture`, data);
        commit('UPDATE_NEWS_IMAGE', { item, image_url: response.data.file_url })
        Vue.toasted.success('Pembaruan foto berita berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pembaruan foto berita gagal';
        Vue.toasted.error(message)
      }
    },

    async deleteNews({ commit }, item) {
      try {
        await axios.delete(`api/news/${item.id}`);
        commit('DELETE_NEWS', item)
        Vue.toasted.success('Penghapusan informasi berita berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penghapusan informasi berita gagal';
        Vue.toasted.error(message)
      }
    },


  }
}