<template>
  <div>
    <section class="main-menu">
      <!-- admin name -->
      <div
        class="hidden md:col-span-1 md:flex h-full justify-center items-center"
      >
        <p class="text-left" v-if="authenticated">
          <span class="block">ADMIN</span>
          <span class="font-bold uppercase tracking-wider">{{
            user.name
          }}</span>
        </p>
      </div>

      <!-- main title -->
      <p class="col-span-4 uppercase text-right md:col-span-3 md:text-center">
        <span class="tracking-wide">sdit almadinah pontianak</span> <br />
        <span class="font-bold tracking-wider font-mono"
          >{{ currentTitle }}
        </span>
      </p>
      <!-- trigger show menu -->
      <div v-if="authenticated" class="main-menu__icon-container">
        <button v-if="!isVisible" @click="openContent">
          <fa-icon icon="bars" class="text-xl"></fa-icon>
        </button>
        <button v-else @click="closeContent">
          <fa-icon icon="times" class="text-xl"></fa-icon>
        </button>
      </div>
    </section>

    <nav class="main-menu__content" v-if="isVisible">
      <div
        v-for="menu in menus"
        :key="menu.title"
        class="main-menu__content-block"
      >
        <p class="main-menu__content-title">{{ menu.title }}</p>
        <router-link
          v-for="(item, itemIndex) in menu.items"
          class="main-menu__content-item"
          :to="item.route"
          :key="itemIndex"
          @click.native="closeContent()"
          >{{ item.text }}</router-link
        >
      </div>

      <div v-if="authenticated" class="main-menu__content-block">
        <p class="main-menu__content-title">{{ user.name }}</p>
        <a href="#" class="main-menu__content-item" @click="triggerBackup()">
          Backup
        </a>
        <a href="#" class="main-menu__content-item" @click="triggerLogout()"
          >Logout</a
        >
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.main-menu {
  @apply text-center align-middle grid grid-cols-5;
  @apply border-green-500 border-t-2 bg-white p-2 w-full;
  @apply shadow fixed top-0 left-0 z-30;
}

.main-menu__icon-container {
  @apply flex h-full justify-center items-center;

  @screen md {
    @apply justify-end mr-8;
  }
}

.main-menu__content {
  min-height: calc(100vh - 64.5px);
  max-height: calc(100vh - 64.5px);
  top: 64.5px;

  @apply fixed z-50 bg-white;
  @apply py-2 px-6 min-w-full max-w-screen-sm overflow-y-auto;

  @screen md {
    @apply min-h-0 shadow right-0 grid grid-cols-2 px-24 mx-auto gap-x-10 pt-8;
  }

  @screen lg {
    @apply grid-cols-3;
  }
}

.main-menu__content-block {
  @apply py-4;

  @screen md {
    @apply border-t-2 border-gray-200 px-6;
  }
}

.main-menu__content-title {
  @apply font-bold uppercase tracking-wider text-green-700;
}

.main-menu__content-item {
  @apply mt-2 block border-white uppercase tracking-wide text-gray-600;
}

.main-menu__content-item,
.router-link-exact-active {
  @apply py-2 px-4;
}

.router-link-exact-active {
  @apply bg-green-300 rounded-lg text-green-900;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MainMenu",
  data() {
    return {
      menus: [
        // {
        //   title: "staf",
        //   items: [{ text: "akun staf", route: "/auth-manager" }],
        // },
        {
          title: "profil sekolah",
          items: [
            { text: "tentang sekolah", route: "/profile/history" },
            { text: "pesan kepala sekolah", route: "/profile/speech" },
            {
              text: "visi, misi, tujuan",
              route: "/profile/education-principle",
            },
            { text: "staf sekolah", route: "/profile/managements" },
            { text: "tim pengajar", route: "/profile/teachers" },
          ],
        },

        {
          title: "pendaftaran",
          items: [
            {
              text: "informasi pendaftaran",
              route: "/registration/information",
            },
            { text: "periode pendaftaran", route: "/registration/period" },
            {
              text: "calon peserta didik",
              route: "/registration/student-candidates",
            },
          ],
        },
        {
          title: "peserta didik",
          items: [
            { text: "peserta didik aktif", route: "/student-management" },
          ],
        },
        {
          title: "berita",
          items: [{ text: "daftar berita", route: "/news-management" }],
        },
      ],
      isVisible: false,
    };
  },

  methods: {
    closeContent() {
      this.isVisible = false;
    },
    openContent() {
      this.isVisible = true;
    },
    triggerLogout() {
      this.closeContent();
      this.logout();
    },

    triggerBackup() {
      window.open(process.env.VUE_APP_API_URL + "/api/backupdb");
    },
    ...mapActions("auth", ["logout"]),
  },
  computed: {
    currentTitle() {
      return this.$route.name;
    },
    ...mapGetters("auth", ["authenticated", "user"]),
  },
};
</script>