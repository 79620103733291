import axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    education_principle: null
  },
  getters: {
    education_principle: state => state.education_principle
  },
  mutations: {
    SET_CONTENT(state, item) {
      state.education_principle = item
    }
  },
  actions: {
    async fetchEducationPrinciple({ commit }) {
      let response = await axios.get('api/profile/education-principle')

      commit('SET_CONTENT', response.data.principle)
    },

    async storeEducationPrinciple({ commit }, item) {
      try {
        let response = await axios.post('api/profile/education-principle', item)

        commit('SET_CONTENT', response.data.principle)
        Vue.toasted.success('Pembaruan prinsip pendidikan berhasil')
      } catch (error) {
        let message = ''
        if (error.response !== undefined) {
          message = error.response.data.message
        } else {
          message = 'Pembaruan prinsip pendidikan gagal'
        }
        Vue.toasted.error(message)
      }
    }
  }
}