import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    profile_picture_url: null,
    introduction: null,
    histories: [],
    history_form_visibility: false,
    selected_history: null,
  },
  getters: {
    profile_picture_url: state => state.profile_picture_url,
    introduction: state => state.introduction,
    histories: state => state.histories,
    history_form_visibility: state => state.history_form_visibility,
    selected_history: state => state.selected_history,
  },
  mutations: {
    SET_PROFILE_PICTURE_URL(state, url) {
      state.profile_picture_url = url
    },
    SET_INTRODUCTION(state, item) {
      state.introduction = item
    },

    STORE_HISTORY(state, item) {
      state.histories.unshift(item)
    },
    INDEX_HISTORIES(state, items) {
      state.histories = items
    },
    UPDATE_HISTORY(state, item) {
      let itemIndex = _.findIndex(state.histories, { id: item.id })
      Vue.set(state.histories, itemIndex, item)
    },
    DELETE_HISTORY(state, item) {
      let itemIndex = _.findIndex(state.histories, { id: item.id })
      Vue.delete(state.histories, itemIndex);
    },
    SET_SELECTED_HISTORY(state, item) {
      state.selected_history = item
    },
    UNSET_SELECTED_HISTORY(state) {
      state.selected_history = null
    },
    SHOW_HISTORY_FORM(state) {
      state.history_form_visibility = true
    },
    HIDE_HISTORY_FORM(state) {
      state.history_form_visibility = false
    },
  },
  actions: {
    /**
     * ------------------------------------------
     * --- Profile Picture -------------------
     * ------------------------------------
     */

    /** School profile picture upload */
    async storeProfilePicture({ commit }, item) {
      try {
        let response = await axios.post('api/profile/main-banner', item);

        commit('SET_PROFILE_PICTURE_URL', response.data.file_url)
        Vue.toasted.success('Foto profil berhasil diunggah')
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Penunggahan foto profil sekolah gagal';

        Vue.toasted.error(errorMessage)
      }
    },

    /** Fetch school profile picture */
    async fetchProfilePicture({ commit }) {
      try {
        let response = await axios.get('api/profile/main-banner/url')
        commit('SET_PROFILE_PICTURE_URL', response.data.file_url)
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Gagal mendapatkan tautan banner utama';

        Vue.toasted.error(errorMessage)
      }
    },

    /**
     * ------------------------------------------
     * --- Introduction ----------------------
     * ------------------------------------
     */

    /** Store Introduction */
    async storeIntroduction({ commit }, item) {
      try {
        let response = await axios.post('api/profile/introduction', item)
        commit('SET_INTRODUCTION', response.data.introduction)
        Vue.toasted.success('Pembaruan pengenalan sekolah berhasil')
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Gagal memperbarui konten pengenalan sekolah';

        Vue.toasted.error(errorMessage)
      }
    },

    /** Fetch introduction */
    async fetchIntroduction({ commit }) {
      try {
        let response = await axios.get('api/profile/introduction')
        commit('SET_INTRODUCTION', response.data.introduction)
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Gagal mendapatkan konten pengenalan sekolah';

        Vue.toasted.error(errorMessage)
      }
    },

    /**
     * ------------------------------------------
     * --- History ---------------------------
     * ------------------------------------
     */
    async fetchHistory({ commit }) {
      let response = await axios.get('api/profile/history')
      commit('INDEX_HISTORIES', response.data.profile_history)
    },
    async storeHistory({ commit }, item) {
      try {
        let response = await axios.post('api/profile/history', item)
        commit('STORE_HISTORY', response.data.profile_history)
        Vue.toasted.success('Penyimpanan sejarah sekolah berhasil')
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Gagal menyimpan sejarah sekolah';

        Vue.toasted.error(errorMessage)
      }
    },
    async updateHistory({ commit }, item) {
      try {
        let response = await axios.put(`api/profile/history/${item.id}`, item)
        commit('UPDATE_HISTORY', response.data.profile_history)
        Vue.toasted.success('Pembaruan sejarah sekolah berhasil')
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Pembaruan sejarah sekolah gagal';

        Vue.toasted.error(errorMessage)
      }
    },
    async deleteHistory({ commit }, item) {
      try {
        await axios.delete(`api/profile/history/${item.id}`)
        commit('DELETE_HISTORY', item)
        Vue.toasted.success('Penghapusan sejarah sekolah berhasil')
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? errorMessage = error.response.data.message
          : 'Penghapusan sejarah sekolah gagal';

        Vue.toasted.error(errorMessage)
      }
    },
  }
}