import axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    opening_speech: null
  },
  getters: {
    opening_speech: state => state.opening_speech
  },
  mutations: {
    SET_CONTENT(state, item) {
      state.opening_speech = item
    }
  },
  actions: {
    async fetchOpeningSpeech({ commit }) {
      let response = await axios.get('api/profile/opening-speech')

      commit('SET_CONTENT', response.data.speech)
    },

    async storeOpeningSpeech({ commit }, item) {
      try {
        let response = await axios.post('api/profile/opening-speech', item)

        commit('SET_CONTENT', response.data.speech)
        Vue.toasted.success('Pembaruan kata sambutan berhasil')
      } catch (error) {
        let message = ''
        if (error.response !== undefined) {
          message = error.response.data.message
        } else {
          message = 'Pembaruan kata sambutan gagal'
        }
        Vue.toasted.error(message)
      }
    },

    async uploadProfilPicture({ commit }, data) {
      try {
        let response = await axios.post('api/profile/opening-speech/upload-profile-picture', data)

        commit('SET_CONTENT', response.data.speech)
        Vue.toasted.success('Pembaruan foto kepala sekolah berhasil')
      } catch (error) {
        let message = ''
        if (error.response !== undefined) {
          message = error.response.data.message
        } else {
          message = 'Pembaruan kata sambutan gagal'
        }
        Vue.toasted.error(message)
      }
    }
  }
}