import Vue from 'vue'
import Vuex from 'vuex'
import news from '@/store/news.js'
import student from '@/store/student.js'
import period from '@/store/period.js'
import schedule from '@/store/schedule.js'
import member from '@/store/member.js'
import candidate from '@/store/candidate.js'
import registration_information from '@/store/registration-information.js'
import school_profile from '@/store/school-profile.js'
import education_principle from '@/store/education-principle.js'
import opening_speech from '@/store/opening-speech.js'
import management_staff from '@/store/management-staff.js'
import management_teacher from '@/store/management-teacher.js'
import auth from '@/store/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    student, auth, period, schedule, member, candidate,
    registration_information, school_profile,
    education_principle, opening_speech, management_staff,
    management_teacher, news
  }
})
