import axios from 'axios'
import Vue from "vue";
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    periods: [],
    form_visibility: false,
    form_data: null,
    selected_period: null,
  },
  getters: {
    periods: state => state.periods,
    selected_period: state => state.selected_period,
    form_visibility: state => state.form_visibility,
    form_data: state => state.form_data
  },
  mutations: {
    RESET(state) {
      state.periods = []
      state.form_visibility = false
      state.form_data = null
      state.selected_period = null
    },
    INDEX_PERIOD(state, items) {
      state.periods = items
    },
    STORE_PERIOD(state, item) {
      // preparing for schedules relationships
      item.schedules = []
      state.periods.unshift(item)
    },
    UPDATE_PERIOD(state, item) {
      let periodList = state.periods
      let itemIndex = _.findIndex(periodList, { id: item.id })
      let schedules = _.cloneDeep(periodList[itemIndex]['schedules'])

      // set item with schedule
      item.schedules = schedules
      Vue.set(periodList, itemIndex, item)
    },
    DELETE_PERIOD(state, itemId) {
      let periodList = state.periods
      let itemIndex = _.findIndex(periodList, { id: itemId })

      Vue.delete(periodList, itemIndex)
    },
    SET_SELECTED_PERIOD(state, item) {
      state.selected_period = item
    },
    UNSET_SELECTED_PERIOD(state) {
      state.selected_period = null
    },
    SET_PERIOD_FORM_DATA(state, item) {
      state.form_data = item
    },
    UNSET_PERIOD_FORM_DATA(state) {
      state.form_data = null
    },
    SHOW_FORM(state) {
      state.form_visibility = true
    },
    HIDE_FORM(state) {
      state.form_visibility = false
    }
  },
  actions: {
    /**
     * Fetch available periods
     * @param {Object} context
     */
    async fetchPeriods({ commit }) {
      let response = await axios.get('api/registration/period')
      commit('INDEX_PERIOD', response.data.period)
    },

    /**
     * Store single period
     * @param {Object} context
     * @param {Object} item
     */
    async storePeriod({ commit }, item) {
      try {
        let response = await axios.post('api/registration/period', item);
        commit('STORE_PERIOD', response.data.period)
        Vue.toasted.success('Penyimpanan periode berhasil')
      } catch (error) {
        Vue.toasted.error(error.response.data.message)
      }
    },

    /**
     * Update single period
     * @param {Object} context
     * @param {Object} item
     */
    async updatePeriod({ commit }, item) {
      try {
        let data = {
          id: item.id,
          name: item.name,
          started_at: item.started_at,
          ended_at: item.ended_at
        }
        let response = await axios.put(`api/registration/period/${item.id}`, data);
        commit('UPDATE_PERIOD', response.data.period)
        Vue.toasted.success('Pengeditan periode berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pengeditan periode gagal';
        Vue.toasted.error(message)
      }
    },

    /**
     * Delete single period
     * @param {Object} context
     * @param {Object} item
     */
    async deletePeriod({ commit }, item) {
      try {
        await axios.delete(`api/registration/period/${item.id}`);
        commit('DELETE_PERIOD', item.id)
        Vue.toasted.success('Penghapusan periode berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penghapusan periode gagal';
        Vue.toasted.error(message)
      }
    },

    async togglePeriodAnnouncement({ commit }, item) {
      try {
        let response = await axios.put(`api/registration/period/annoucement/toggle/${item.id}`, item);
        let resultSentence = 'Pengumuman kelulusan berhasil di'
        resultSentence += response.data.period.is_announced ? 'publikasi' : 'sembunyikan'
        commit('UPDATE_PERIOD', response.data.period)
        Vue.toasted.success(resultSentence)
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pengeditan periode gagal';
        Vue.toasted.error(message)
      }
    }
  }
}