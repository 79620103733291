import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    information: null,
  },
  getters: {
    information: state => state.information
  },
  mutations: {
    RESET(state) {
      state.information = null
    },
    STORE_CONTENT(state, information) {
      state.information = information
    }
  },
  actions: {
    /** Write content */
    async storeContent({ commit }, item) {
      try {
        let response = await axios.post('api/registration/information', item)
        commit('STORE_CONTENT', response.data.information)
        Vue.toasted.success('Penulisan informasi pendaftaran berhasil');
      } catch (error) {
        let errorMessage = error.response !== undefined
          ? error.response.data.message
          : 'Penulisan informasi pendaftaran gagal';

        Vue.toasted.error(errorMessage)
      }
    },

    /** Fetch content */
    async fetchContent({ commit }) {
      let response = await axios.get('api/registration/information')
      commit('STORE_CONTENT', response.data.information)
    }
  }
}