import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGate from '../views/AuthGate.vue'
import store from '@/store'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: AuthGate
  },

  /** Profile ----------------------------------------------------------------- */
  /** History */
  {
    path: '/profile/history',
    name: 'Sejarah Sekolah',
    component: () => import(/* webpackChunkName: "profile-sh" */ '../views/Profile/SchoolHistory.vue')
  },
  /** Opening Speech */
  {
    path: '/profile/speech',
    name: 'Sambutan Kepala Sekolah',
    component: () => import(/* webpackChunkName: "profile-os" */ '../views/Profile/OpeningSpeech.vue')
  },
  /** vision, mission, etc. */
  {
    path: '/profile/education-principle',
    name: 'Visi, Misi, Tujuan',
    component: () => import(/* webpackChunkName: "profile-ep" */ '../views/Profile/EducationPrinciple.vue')
  },
  /** Staff */
  {
    path: '/profile/managements',
    name: 'Staf Manajemen',
    component: () => import(/* webpackChunkName: "profile-ms" */ '../views/Profile/ManagementStaff.vue')
  },
  /** Teachers */
  {
    path: '/profile/teachers',
    name: 'Tim Pengajar',
    component: () => import(/* webpackChunkName: "profile-tt" */ '../views/Profile/TeachingTeam.vue')
  },
  /** ------------------------------------------------------------------------- */

  /** New Student Registration ------------------------------------------------ */
  {
    path: '/registration/information',
    name: 'Informasi Pendaftaran',
    component: () => import(
      /* webpackChunkName: "registration-ri" */
      '../views/Registration/RegistrationInformation.vue')
  },
  {
    path: '/registration/period',
    name: 'Periode Pendaftaran',
    component: () => import(
      /* webpackChunkName: "registration-rp" */
      '../views/Registration/PeriodManagement.vue'
    )
  },
  {
    path: '/registration/student-candidates',
    name: 'Calon Peserta Didik',
    component: () => import(
      /* webpackChunkName: "registration-rc" */
      '../views/Registration/CandidateManagement.vue')
  },
  /** ------------------------------------------------------------------------- */

  /** News Owned */
  {
    path: '/news-management',
    name: 'Manajemen Berita',
    component: () => import(
      /* webpackChunkName: "newsm" */
      '../views/NewsManagement.vue')
  },

  /** Student Management */
  {
    path: '/student-management',
    name: 'Manajemen Peserta Didik',
    component: () => import(
      /* webpackChunkName: "studentm" */
      '../views/StudentManagement.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = store.getters['auth/authenticated'] === true;
  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  else next()
})

export default router
