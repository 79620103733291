export default {
  namespaced: true,
  state: {
    list_visibility: false
  },
  getters: {
    list_visibility: state => state.list_visibility,
    members(state, getters, rootState, rootGetters) {
      let selectedSchedule = rootGetters['schedule/selected_schedule']

      return selectedSchedule !== null
        ? selectedSchedule.members
        : null;
    },
  },
  mutations: {
    RESET(state) {
      state.list_visibility = false
    },
    SHOW_LIST(state) {
      state.list_visibility = true
    },
    HIDE_LIST(state) {
      state.list_visibility = false
    }
  },
  actions: {
    async printMembers() { }
  }
}