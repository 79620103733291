import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    initial_form_visibility: false,
    candidates: [],
    selected_candidate: null,
    links: [],
  },
  getters: {
    candidates: state => state.candidates,
    initial_form_visibility: state => state.initial_form_visibility,
    selected_candidate: state => state.selected_candidate,
    links: state => state.links
  },
  mutations: {
    RESET(state) {
      state.initial_form_visibility = false
      state.candidates = []
      state.selected_candidate = null
    },
    INDEX_CANDIDATE(state, items) {
      state.candidates = items
    },
    STORE_CANDIDATE(state, item) {
      state.candidates.unshift(item)
    },
    UPDATE_CANDIDATE(state, item) {
      let targetIndex = _.findIndex(state.candidates, { id: item.id })

      /** Keep the relationship intact */
      let relationshipSchedule = state.candidates[targetIndex]['schedule']
      item.schedule = relationshipSchedule

      Vue.set(state.candidates, targetIndex, item)
    },
    DELETE_CANDIDATE(state, item) {
      let targetIndex = _.findIndex(state.candidates, { id: item.id })
      Vue.delete(state.candidates, targetIndex)
    },
    SET_SELECTED_CANDIDATE(state, item) {
      state.selected_candidate = item
    },
    UNSET_SELECTED_CANDIDATE(state) {
      state.selected_candidate = null
    },
    SHOW_INIT_FORM(state, item = null) {
      state.initial_form_visibility = true
      state.selected_candidate = item
    },
    HIDE_INIT_FORM(state) {
      state.initial_form_visibility = false
      state.selected_candidate = null
    }
  },
  actions: {
    /** Indexing candidates */
    async fetchCandidates({ commit, state }, filter = '') {
      try {
        let response = await axios.get('api/student/candidate' + filter)
        state.links = response.data.meta.links
        commit('INDEX_CANDIDATE', response.data.student)
      } catch (error) {
        Vue.toasted.error(error.response.data.message)
      }
    },

    async fetchCandidatesUsingPagination({ commit, state }, url) {
      try {
        let response = await axios.get(url)
        state.links = response.data.meta.links
        commit('INDEX_CANDIDATE', response.data.student)
      } catch (error) {
        Vue.toasted.error(error.response.data.message)
      }
    },

    /** Store single candidate */
    async storeCandidate({ commit }, item) {
      try {
        let response = await axios.post('api/student', item)
        commit('STORE_CANDIDATE', response.data.student)
        Vue.toasted.success('Pendaftaran awal calon peserta didik berhasil')
      } catch (error) {
        if (error.response !== undefined) {
          Vue.toasted.error(error.response.data.message)
        } else {
          Vue.toasted.error('Pendaftaran awal calon peserta didik gagal')
        }
      }
    },

    /** Update single candidate */
    async updateCandidate({ commit }, item) {
      try {
        let response = await axios.put(`api/student/${item.id}`, item)
        commit('UPDATE_CANDIDATE', response.data.student)
        Vue.toasted.success('Pengeditan calon peserta didik berhasil')
      } catch (error) {
        error
        Vue.toasted.error('Pengeditan calon peserta didik gagal')
      }
    },

    /** Delete single candidate */
    /** Accept single candidate */
    /** Update single candidate */
    async acceptCandidate({ commit }, item) {
      try {
        let response = await axios.put(`api/student/accept/${item.id}`, item)
        commit('DELETE_CANDIDATE', response.data.student)
        Vue.toasted.success('Penerimaan calon peserta didik berhasil')
      } catch (error) {
        error
        Vue.toasted.error('Penerimaan calon peserta didik gagal')
      }
    }
    // api/student/accept/{student}
  }
}