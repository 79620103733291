<template>
  <div class="bg-gray-50 min-h-screen">
    <form
      class="py-4 px-8 mx-auto max-w-xs bg-white shadow"
      action="#"
      @submit.prevent="login"
    >
      <div>
        <label class="" for="email">Email</label>
        <input
          autofocus
          type="text"
          name="email"
          id="email"
          v-model="form.email"
        />
      </div>
      <div>
        <label for="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          v-model="form.password"
        />
      </div>
      <div class="text-center">
        <button class="button --primary" type="submit">Masuk</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async login() {
      await this.attempLogin(this.form);
      this.$router.replace({ name: "Home" });
    },
    ...mapActions("auth", {
      attempLogin: "login",
    }),
  },
  name: "AuthGate",
  components: {
    //
  },
};
</script>

<style lang="scss" scoped>
label {
  @apply block uppercase tracking-wider text-sm mt-4;
}

input {
  @apply w-full;
}
</style>