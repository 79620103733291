import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    form_visibility: false,
    staffs: [],
    selected_staff: null,
  },
  getters: {
    form_visibility: state => state.form_visibility,
    staffs: state => state.staffs,
    selected_staff: state => state.selected_staff
  },
  mutations: {
    INDEX_STAFF(state, items) {
      state.staffs = items
    },
    STORE_STAFF(state, item) {
      state.staffs.unshift(item)
    },
    UPDATE_STAFF(state, item) {
      let itemIndex = _.findIndex(state.staffs, { id: item.id })
      Vue.set(state.staffs, itemIndex, item)
    },
    UPDATE_STAFF_IMAGE(state, { item, image_url }) {
      let itemIndex = _.findIndex(state.staffs, { id: item.id })
      item.image_url = image_url
      Vue.set(state.staffs, itemIndex, item)
    },
    DELETE_STAFF(state, item) {
      let itemIndex = _.findIndex(state.staffs, { id: item.id })
      Vue.delete(state.staffs, itemIndex)
    },
    SHOW_FORM(state) {
      state.form_visibility = true
    },
    HIDE_FORM(state) {
      state.form_visibility = false
    },
    SET_SELECTED_STAFF(state, item) {
      state.selected_staff = item
    },
    UNSET_SELECTED_STAFF(state) {
      state.selected_staff = null
    }

  },
  actions: {
    async fetchStaff({ commit }) {
      let response = await axios.get('api/staff')
      commit('INDEX_STAFF', response.data.staff)
    },

    async storeStaff({ commit }, item) {
      try {
        let response = await axios.post('api/staff', item);
        commit('STORE_STAFF', response.data.staff)
        Vue.toasted.success('Penyimpanan informasi staf berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penyimpanan informasi staf gagal';
        Vue.toasted.error(message)
      }
    },

    async updateStaff({ commit }, item) {
      try {
        let response = await axios.put(`api/staff/${item.id}`, item);
        commit('UPDATE_STAFF', response.data.staff)
        Vue.toasted.success('Pembaruan informasi staf berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pembaruan informasi staf gagal';
        Vue.toasted.error(message)
      }
    },

    async updateStaffPhoto({ commit }, { item, data }) {
      try {
        let response = await axios.post(`api/staff/${item.id}/profile-picture`, data);
        commit('UPDATE_STAFF_IMAGE', { item, image_url: response.data.file_url })
        Vue.toasted.success('Pembaruan foto staf berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Pembaruan foto staf gagal';
        Vue.toasted.error(message)
      }
    },

    async deleteStaff({ commit }, item) {
      try {
        await axios.delete(`api/staff/${item.id}`);
        commit('DELETE_STAFF', item)
        Vue.toasted.success('Penghapusan informasi staf berhasil')
      } catch (error) {
        let message = error.response !== undefined
          ? error.response.data.message
          : 'Penghapusan informasi staf gagal';
        Vue.toasted.error(message)
      }
    },


  }
}